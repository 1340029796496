.team-container {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  margin: 3px 0px;
}

.mainTeam {
  border-radius: 5px;
  background-color: #fdf4c5;
}

.team {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  hyphens: auto;
  width: 100%;
}

.teamName {
  margin-left: 5px;
}

.teamName:hover {
  text-decoration: underline;
}

.team-score-small {
  font-family: SmartScoreNumbers, sans-serif;
  font-weight: bolder;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.team-score-small--live {
  color: var(--sm-color-red);
}

.teams-small {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 6px;
}
