.circularProgress {
  position: absolute;
}

.childrenScale {
  position: relative;
  transform: scale(0.6);
}

.value,
.childrenScale,
.circularProgress,
.valueBackground {
  width: 100% !important;
  height: 100% !important;
}

.valueBackground {
  position: absolute;
  left: 0%;
  opacity: 0.2;
}

.formContainer {
  z-index: 99;
  top: -13px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.form {
  padding: 2px 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 12px;
  color: white;
  font-weight: 500;
  white-space: nowrap;
  font-family: SmartScoreNumbers, sans-serif;
}

.smallForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 21px;
  border-radius: 4px;
  font-size: 9px;
  letter-spacing: 0.7px;
  opacity: 0;
  font-weight: 600;
  color: var(--sm-color-light);
  line-height: 0px;
  font-family: SmartScoreNumbers, sans-serif;
}

.form--loaded {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

@media (max-width: 480px) {
  .form {
    font-size: 10px;
    padding: 1px 3px;
  }
}
