.header {
  display: flex;
  align-items: center;
  padding: 6px 15px 6px 10px;
  width: 100%;
  margin: 0;
  min-height: 45px;
  box-sizing: border-box;
  content-visibility: auto;
  /* contain-intrinsic-size: 45px; */
}

.match-table-title:hover,
.standings:hover {
  text-decoration: underline;
}

.standings {
  font-size: 14px;
  color: var(--sm-color-secondary);
}

.match-icon,
.match-icon--world {
  margin-right: 8px;
  min-height: 15px;
  box-shadow: 0px 0px 3px gray;
}

.match-icon--world {
  margin-right: 8px;
}

.match-table-title {
  font-weight: 400;
  word-wrap: break-word;
  hyphens: auto;
  font-size: 22px;
  color: var(--sm-text-color) !important;
}

.standings-link {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: var(--sm-progress-color);
}

.skeletonHeaderTitle {
  width: 100%;
  max-width: 350px;
  margin-left: 8px;
}

.headerRow {
  height: 33px !important;
}

.favorites {
  margin: 0px 6px 0px 0px;
}

@media (max-width: 1400px) {
  .favorites {
    margin: 0px 6px 0px -9px;
  }
}

@media (max-width: 1000px) {
  .match-table-title {
    font-size: 20px;
  }
}

@media (max-width: 760px) {
  .match-table-title {
    font-size: 18px;
  }
}

@media (max-width: 560px) {
  .match-table-title {
    font-size: 14px;
  }

  .match-icon {
    max-height: 30px;
    max-width: 30px;
  }

  .skeletonHeaderTitle {
    max-width: 180px;
  }

  .standings {
    display: none;
  }
}

@media (max-width: 320px) {
  .match-table-title {
    font-size: 16px !important;
  }
}
