.cta {
  width: 100%;
  white-space: nowrap;
  padding: 5px;
  font-size: 14px;
  position: relative;
}

.isLive::after {
  will-change: transform;
  content: 'LIVE';
  position: absolute;
  top: -6px;
  right: -9px;
  transform-origin: center;
  width: 21px;
  height: 12.5px;
  font-size: 8px;
  color: #f1f2f2;
  border-radius: 1.5px;
  text-align: center;
  background: linear-gradient(160deg, #ff4764 50%, #ff3051 50%);
  animation: tossing 2s infinite;
  box-shadow: 0px 0px 3px rgba(255, 71, 99, 0.5);
}

.cta {
  border-radius: 4px;
  max-width: 150px;
  border: 1px solid var(--sm-primary-color) !important;
  color: var(--sm-primary-color) !important;
  min-width: auto !important;
}

.icon {
  width: 20px;
  height: 20px;
}

@media (min-width: 400px) {
  .icon {
    display: none;
  }
}

@media (max-width: 399px) {
  .label {
    display: none;
  }
}

@media (min-width: 360px) and (max-width: 1920px) {
  .cta {
    font-size: calc(11px + 3 * var(--sm-calc-fullHD));
  }
}

@media (max-width: 360px) {
  .cta {
    font-size: 11px;
  }
}

@keyframes tossing {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}
