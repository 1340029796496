.teams {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.teamLogo {
  min-width: 23px;
  min-height: 23px;
}

.teamName {
  margin-left: 5px;
  width: 100%;
  height: 17px;
  align-self: center;
}
