.table {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: content-box;
  contain: layout;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  border-top: 1px solid rgb(224, 224, 224);
  min-height: 34px;
  content-visibility: auto;
  /* contain-intrinsic-size: 34px; */
}

.matchRow {
  min-height: 76px;
  content-visibility: auto;
  /* contain-intrinsic-size: 76px; */
}

.matchRow > div {
  flex-direction: column;
}

.row > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 6px;
  font-size: 14px;
}

.favorite,
.time,
.odds {
  text-align: center;
  max-width: 30px;
}

.odds,
.predictions {
  justify-content: center !important;
}

.matchRow .time {
  font-family: SmartScoreNumbers, sans-serif;
}

.matchRow .odds,
.predictions > span {
  font-family: SmartScoreNumbers, sans-serif;
}

.betLabel {
  font-family: SmartScoreNumbers, sans-serif;
  display: none;
}

.teams {
  text-align: left;
  word-break: break-word;
}

.form {
  max-width: 30px;
}

.tip > a {
  min-width: auto !important;
}

.last5 {
  min-width: 160px;
  max-width: 180px;
}

.last5Container {
  min-width: 142px;
}

.predictions {
  min-width: 70px;
  max-width: 130px;
}

.tip {
  max-width: 40px;
  padding-right: 13px !important;
  white-space: normal;
  text-align: center;
}

.headerRow {
  height: 33px;
}

.score {
  min-width: 18px;
  max-width: 18px;
  font-family: SmartScoreNumbers, sans-serif;
}

.elapsed {
  color: var(--sm-color-red);
  font-family: SmartScoreNumbers, sans-serif;
  font-weight: 500;
}

.elapsed:after {
  content: "'";
  animation: blink 2s infinite;
}

@media (max-width: 359px) {
  .odds,
  .form {
    display: none !important;
  }
}

@media (max-width: 679px) {
  .last5 {
    display: none !important;
  }
}

@media (min-width: 399px) {
  .tip {
    max-width: 55px;
  }
}

@media (max-width: 374px) {
  .tip {
    max-width: 35px;
    min-width: 35px;
  }
}

@media (min-width: 480px) {
  .favorite,
  .time {
    max-width: 35px;
  }

  .teams {
    min-width: 180px;
  }

  .odds {
    max-width: 80px;
    min-width: 40px;
  }

  .tip {
    max-width: 60px;
    min-width: 60px;
  }
}

@media (min-width: 860px) {
  .time {
    max-width: 100px;
    min-width: 100px;
  }
  .tip {
    min-width: 80px;
    max-width: 80px;
  }
}

@media (max-width: 767px) {
  .predictions {
    display: none !important;
  }
}

@media (min-width: 1400px) {
  .favorite {
    max-width: 50px;
  }

  .time {
    max-width: 130px;
    min-width: 130px;
  }
  .tip {
    max-width: 100px;
    min-width: 100px;
  }
}

@media (min-width: 1921px) {
  .last5,
  .predictions {
    max-width: 500px;
  }
}

@media (max-width: 480px) {
  .matchRow {
    min-height: 85px;
    content-visibility: auto;
    /* contain-intrinsic-size: 85px; */
  }

  .row > div {
    font-size: 12px;
  }
  .headerRow {
    height: 30px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .betLabel {
    display: flex;
  }
  .odds,
  .predictions {
    flex-direction: row !important;
  }
}

@media (min-width: 1600px) {
  .odds,
  .predictions {
    flex-direction: row !important;
  }

  .odds {
    max-width: 100px;
  }

  .predictions {
    max-width: 180px;
  }

  .betLabel {
    display: flex;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
