.match-status-type {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  font-family: SmartScoreNumbers, sans-serif;
}

.match-status-type:not(:first-of-type) {
  margin-left: 8px;
}

.D {
  border-color: var(--sm-color-light-orange);
  color: var(--sm-color-light-orange) !important;
}

.L {
  border-color: var(--sm-color-red);
  color: var(--sm-color-red) !important;
}

.W {
  border-color: var(--sm-primary-color);
  color: var(--sm-primary-color) !important;
}
